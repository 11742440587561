<template>
  <div class="reports-page w-full h-full flex flex-col overflow-y-clip px-safe-4">
    <PageHeader title="Reports" />
    <TabView
      v-model:activeIndex="activeIndex"
      :pt="{
        root: 'w-full h-full flex flex-col',
        panelContainer: 'w-full h-full'
      }"
    >
      <!-- All Reports -->
      <TabPanel>
        <template #header> All Reports </template>
        <div class="flex flex-row flex-wrap gap-x-3 max-w-6xl">
          <Card
            class="mb-3 button min-w-64 cursor-pointer hover:bg-surface-100 transition"
            v-for="report in reports"
            :key="`card-${report.reportType}`"
            @click="startingTab = [report.title]"
          >
            <template #icon>
              <font-awesome-icon icon="table" />
            </template>
            <span class="title">{{ report.title }}</span>
          </Card>
        </div>
      </TabPanel>

      <!-- Individual Reports -->
      <TabPanel
        v-for="report in reports"
        :key="report.index"
        :pt="{
          root: 'w-full h-full flex flex-col'
        }"
      >
        <template #header>
          {{ report.title }}
        </template>
        <Report
          :tabIndex="report.index"
          :activeReportIndex="activeIndex"
          :reportIndex="report.index"
          :reportType="report.reportType"
          :entity="report.entity"
        />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import PageMixin from '../mixins/Page'
import Report from '../ui/Report/Report.vue'
import PageHeader from '@/components/layout/page/PageHeader.vue'

export default {
  components: {
    PageHeader,
    Report,
    TabView,
    TabPanel
  },
  mixins: [PageMixin],

  data() {
    return {
      reportId: this.$route.params.id,

      reports: [
        {
          title: 'Estimates',
          reportType: 'quotes',
          entity: 'quote',
          index: 1
        },
        {
          title: 'Booked Projects',
          reportType: 'projects',
          entity: 'quote',
          index: 2
        },
        {
          title: 'Project Invoices',
          reportType: 'invoices',
          entity: 'invoice',
          index: 3
        },
        {
          title: 'Clients Summary',
          reportType: 'clients',
          entity: 'client',
          index: 4
        },
        {
          title: 'AR Aging',
          reportType: 'ar_aging',
          entity: 'quote',
          index: 5
        },
        {
          title: 'Bolster Impact',
          reportType: 'costcertified_impact',
          entity: 'quote',
          index: 6
        }
      ],

      startingTab:
        this.$route.query && this.$route.query.tab
          ? c.makeArray(this.$route.query.tab)
          : ['All Reports'],

      activeIndex: 0
    }
  },

  computed: {
    routeReport() {
      return this.$route.params.id
    }
  },

  watch: {
    startingTab(tab) {
      this.activeIndex = this.reports.find(({ title }) => title === tab[0]).index
    },
    activeIndex(newIndex) {
      const tab = this.reports.find(({ index }) => index === newIndex)?.title || 'All Reports'
      this.$router
        .push({
          query: {
            tab
          }
        })
        .catch(() => {})
    }
  }
}
</script>
